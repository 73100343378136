:root {
    --mainColor: #0466c8;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
    font-family: 'Poppins', sans-serif;
}
.wrapper {
    position: fixed;
    width: 100%;
    z-index: 9999;
    top: 0;
}
.wrapper .top-nav {
    width: 100%;
    height: 65px;
    background: #fff;
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 1;
    /* max-width: 1300px; */
    margin-left: auto;
    margin-right: auto;
}
.wrapper .top-nav .left {
    display: flex;
    align-items: center;
}
.wrapper .top-nav .left .logo {
    margin-right: 25px;
}
.centre {
    width: 100%;
}
.wrapper .top-nav .centre .nav-menu {
    display: flex;
    justify-content: center;
    width: 100%;
    z-index: 9999;
    cursor: pointer;
}
.wrapper .top-nav .centre ul li {
    margin: 10px;
}
.wrapper .top-nav .centre ul li:hover {
    color: #ffffff;
}
.nav-link {
    display: flex;
    align-items: center;
    height: 100%;
    color: #00132d;
    text-decoration: inherit;
    font-size: 14px;
    cursor: pointer;
    background:
    linear-gradient(
    to bottom, var(--mainColor) 0%, var(--mainColor) 100%);
    background-position: 0 100%;
    background-repeat: repeat-x;
    background-size: 3px 3px;
    color: #000;
    /* text-decoration: none; */
    transition: background-size .2s;
}
.nav-link:hover {
    background-size: 4px 50px;
    color: #ffffff;
}
.wrapper .top-nav .right ul {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
}
.wrapper .top-nav .right ul li {
    margin: 0 7px;
    color: #00132d7e
}
.wrapper .bottom-nav {
    width: 100%;
    background: #080A34;
    height: 45px;
    padding: 0 50px;
}
.wrapper .bottom-nav ul{
    width: 100%;
    height: 45px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.wrapper .bottom-nav ul li {
    color: #ffffff;
    letter-spacing: 2px;
    text-transform: uppercase;
    font-size: 12px;
    margin: 5px;
}
.wrapper .bottom-nav ul li:nth-child(2) {
    color: red;
}

/* select all commented code below and press ctrl and / . That will make the circle flash. Remember to save after doing this (ctrl+S). */
@keyframes up-right {
    0% {
        transform: scale(1);
        opacity: .25
    }
    50% {
        transform: scale (1, 5);
        opacity: 1;
    }
    100% {
        transform: scale(1);
        opacity: .25;
    }
}

.circle {
    border-radius: 50%;
    width: 15px;
    height: 15px;
    opacity: .75;
}
.red {
    background-color: red;
    top: 50%;
    left: 50%;
    -webkit-animation: up-right 2s infinite;
    -moz-animation: up-right 2s infinite;
    -o-animation: up-right 2s infinite;
    animation: up-right 2s infinite;
}
.fa-play {
    margin: 5px 10px 5px 20px;
}
.logo {
    height: 50px;
    width: 48px;
}

.logo:hover {
    cursor: pointer;
}

.menu-icon {
    display: none;
}

.audio-player-text {
    color: #ffffff;
    font-size: 12px;
    letter-spacing: 2px;
    text-transform: uppercase;
    margin: 0 0 0 10px;
}

.audio-player-container {
    display: flex;
    margin-left: 10px;
}

.mixcloud-icon {
    filter: invert(30%) sepia(86%) saturate(1916%) hue-rotate(197deg) brightness(99%) contrast(110%);
}

@media screen and (max-width: 768px) {
    .centre {
        display: flex;
        justify-content: center;
        width: 33%;
    }
    .wrapper .top-nav .right ul {
        display: none;
    }
    .wrapper .top-nav .centre .nav-menu {
        display: none;
    }
    .nav-menu-active {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-top: 1.9rem;
        background-color: #ffffff;
        left: 0;
        opacity: 1;
        transition: all 0.6 ease;
        z-index: 1;
        position: absolute;
    }
    .logo {
        height: 55px;
        width: 55px;
        margin: -14px -10px -10px -25px;
    }
    .nav-link {
        text-align: center;
        padding: 1.5rem;
        width: 100%;
        display: table;
        background: none;
        font-size: 1.3rem;
    }
    .nav-item {
        display: flex;
        justify-content: flex-end;
        width: 100%;
        margin: 10px;
    }
    .logo-container {
        position: absolute;
        top: 0;
        left: 0;
        padding: 20px 40px 40px 40px;
    }
    .wrapper .top-nav .right .menu-icon {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;
        color: #00132d;
    }
    .menu-icon {
        margin-top: -10px;
    }
    .fa .fa-times {
        font-size: 2rem;
    }
    .hero-container {
        position: relative;
    }
    .cards {
        position: relative;
    }
}

@media screen and (max-width: 320px) {
    .footer-wrapper .right ul {
        display: none;
    }
    .wrapper .bottom-nav ul {
        margin-left: 20px;
    }
    .wrapper .bottom-nav {
        padding: 0 60px;
    }
}

@media screen and (min-width: 375px) {
    .footer-wrapper .right ul {
        display: none;
    }
    .wrapper .bottom-nav ul {
        margin-left: 10px;
    }
    .wrapper .bottom-nav {
        padding: 0 100px;
    }
}

@media screen and (min-width: 425px) {
    .wrapper .bottom-nav {
        padding: 0 120px;
    }
}

@media screen and (min-width: 425px) {
    .wrapper .bottom-nav {
        padding: 0 20px;
    }
}