@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

html {
    height: 100%;
    width: 100%;
    margin: 0;
}
  
body {
    margin: 0;
    width: 100%;
    height: 100%;
    background-color: #000000;
}