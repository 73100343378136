.schedule-container {
    /* height: 250vh; */
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    object-fit: contain;
    background-color: #020211;
}
.schedule-title {
    color: #007bff;
    font-weight: bolder;
    margin: 60px;
}

.schedule-table {
    width: 80%;
    margin-bottom: 75px;
}

tr {
    display: flex;
    justify-content: space-between;
    margin: 2rem;
    border-top: solid 2px #007bff;
}

td {
    color: #ffffff;
    margin: 1rem;
    font-size: 25px;
}

.show-name-text {
    margin-left: 40px;
}

.show-date-row {
    border-top: none;
    margin-bottom: -20px;
}

.show-date-text {
    font-size: 35px;
}

.takeover-show-text {
    color: #9C9DAD;
    margin-top: 0;
    margin-bottom: 10px;
}

@media screen and (max-width: 768px) {
    .show-date-text {
        text-align: center;
    }
}

@media screen and (min-width: 425px) {
    tr {
        margin: 1rem;
    }
    .schedule-table {
        width: 90%;
    }
}

@media screen and (min-width: 375px) {
    tr {
        margin: 1rem;
    }
    .schedule-table {
        width: 90%;
    }
}

@media screen and (min-width: 320px) {
    tr {
        margin: 1rem;
    }
    td {
        font-size: 11px;
        margin: 0.5rem;
    }
    .schedule-table {
        width: 90%;
    }
    .show-date-text {
        font-size: 22px;
        margin-bottom: 2rem;
    }
    .schedule-title {
        font-size: 2rem;
    }
}

@media screen and (min-width: 768px) {
    .show-date-text {
        font-size: 38px;
        text-align: left;
    }
    .schedule-table {
        width: 80%;
    }
    td {
        font-size: 24px;
        margin: 1rem;
    }
    .schedule-title {
        font-size: 3.25rem;
    }
}

@media screen and (min-width: 1024px) {
    tr {
        margin: 1.5rem;
    }
}

@media screen and (min-width: 2560px) {
    .schedule-title {
        font-size: 5.25rem;
    }
    .show-date-text {
        font-size: 60px;
    }
    td {
        font-size: 40px;
        margin: 2rem;
    }
}