@import url(https://fonts.googleapis.com/css2?family=Poppins&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Barlow:ital,wght@1,600&display=swap);


html {
    height: 100%;
    width: 100%;
    margin: 0;
}
  
body {
    margin: 0;
    width: 100%;
    height: 100%;
    background-color: #000000;
}
:root {
    --mainColor: #0466c8;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
    font-family: 'Poppins', sans-serif;
}
.wrapper {
    position: fixed;
    width: 100%;
    z-index: 9999;
    top: 0;
}
.wrapper .top-nav {
    width: 100%;
    height: 65px;
    background: #fff;
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 1;
    /* max-width: 1300px; */
    margin-left: auto;
    margin-right: auto;
}
.wrapper .top-nav .left {
    display: flex;
    align-items: center;
}
.wrapper .top-nav .left .logo {
    margin-right: 25px;
}
.centre {
    width: 100%;
}
.wrapper .top-nav .centre .nav-menu {
    display: flex;
    justify-content: center;
    width: 100%;
    z-index: 9999;
    cursor: pointer;
}
.wrapper .top-nav .centre ul li {
    margin: 10px;
}
.wrapper .top-nav .centre ul li:hover {
    color: #ffffff;
}
.nav-link {
    display: flex;
    align-items: center;
    height: 100%;
    color: #00132d;
    text-decoration: inherit;
    font-size: 14px;
    cursor: pointer;
    background:
    linear-gradient(
    to bottom, #0466c8 0%, #0466c8 100%);
    background:
    linear-gradient(
    to bottom, var(--mainColor) 0%, var(--mainColor) 100%);
    background-position: 0 100%;
    background-repeat: repeat-x;
    background-size: 3px 3px;
    color: #000;
    /* text-decoration: none; */
    transition: background-size .2s;
}
.nav-link:hover {
    background-size: 4px 50px;
    color: #ffffff;
}
.wrapper .top-nav .right ul {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
}
.wrapper .top-nav .right ul li {
    margin: 0 7px;
    color: #00132d7e
}
.wrapper .bottom-nav {
    width: 100%;
    background: #080A34;
    height: 45px;
    padding: 0 50px;
}
.wrapper .bottom-nav ul{
    width: 100%;
    height: 45px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.wrapper .bottom-nav ul li {
    color: #ffffff;
    letter-spacing: 2px;
    text-transform: uppercase;
    font-size: 12px;
    margin: 5px;
}
.wrapper .bottom-nav ul li:nth-child(2) {
    color: red;
}

/* select all commented code below and press ctrl and / . That will make the circle flash. Remember to save after doing this (ctrl+S). */
@keyframes up-right {
    0% {
        transform: scale(1);
        opacity: .25
    }
    50% {
        transform: scale (1, 5);
        opacity: 1;
    }
    100% {
        transform: scale(1);
        opacity: .25;
    }
}

.circle {
    border-radius: 50%;
    width: 15px;
    height: 15px;
    opacity: .75;
}
.red {
    background-color: red;
    top: 50%;
    left: 50%;
    animation: up-right 2s infinite;
}
.fa-play {
    margin: 5px 10px 5px 20px;
}
.logo {
    height: 50px;
    width: 48px;
}

.logo:hover {
    cursor: pointer;
}

.menu-icon {
    display: none;
}

.audio-player-text {
    color: #ffffff;
    font-size: 12px;
    letter-spacing: 2px;
    text-transform: uppercase;
    margin: 0 0 0 10px;
}

.audio-player-container {
    display: flex;
    margin-left: 10px;
}

.mixcloud-icon {
    -webkit-filter: invert(30%) sepia(86%) saturate(1916%) hue-rotate(197deg) brightness(99%) contrast(110%);
            filter: invert(30%) sepia(86%) saturate(1916%) hue-rotate(197deg) brightness(99%) contrast(110%);
}

@media screen and (max-width: 768px) {
    .centre {
        display: flex;
        justify-content: center;
        width: 33%;
    }
    .wrapper .top-nav .right ul {
        display: none;
    }
    .wrapper .top-nav .centre .nav-menu {
        display: none;
    }
    .nav-menu-active {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-top: 1.9rem;
        background-color: #ffffff;
        left: 0;
        opacity: 1;
        transition: all 0.6 ease;
        z-index: 1;
        position: absolute;
    }
    .logo {
        height: 55px;
        width: 55px;
        margin: -14px -10px -10px -25px;
    }
    .nav-link {
        text-align: center;
        padding: 1.5rem;
        width: 100%;
        display: table;
        background: none;
        font-size: 1.3rem;
    }
    .nav-item {
        display: flex;
        justify-content: flex-end;
        width: 100%;
        margin: 10px;
    }
    .logo-container {
        position: absolute;
        top: 0;
        left: 0;
        padding: 20px 40px 40px 40px;
    }
    .wrapper .top-nav .right .menu-icon {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;
        color: #00132d;
    }
    .menu-icon {
        margin-top: -10px;
    }
    .fa .fa-times {
        font-size: 2rem;
    }
    .hero-container {
        position: relative;
    }
    .cards {
        position: relative;
    }
}

@media screen and (max-width: 320px) {
    .footer-wrapper .right ul {
        display: none;
    }
    .wrapper .bottom-nav ul {
        margin-left: 20px;
    }
    .wrapper .bottom-nav {
        padding: 0 60px;
    }
}

@media screen and (min-width: 375px) {
    .footer-wrapper .right ul {
        display: none;
    }
    .wrapper .bottom-nav ul {
        margin-left: 10px;
    }
    .wrapper .bottom-nav {
        padding: 0 100px;
    }
}

@media screen and (min-width: 425px) {
    .wrapper .bottom-nav {
        padding: 0 120px;
    }
}

@media screen and (min-width: 425px) {
    .wrapper .bottom-nav {
        padding: 0 20px;
    }
}
.player-wrapper {
    display: flex;
}

.play-btn {
    background: none;
    border: none;
    display: flex;
    align-items: center;
    color: white;
    /* Uncomment the line below */
    cursor: pointer;
}

/* video {
    object-fit: cover;
    width: 100%;
    height: 80%;
    position: fixed;
    z-index: 1;
} */
.hero-container {
    padding: 4rem 0 4rem 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    object-fit: contain;
    background-color: #020211;
    font-family: ;
    height: 50%;
}
.hero-container > h1 {
    color: #ffffff;
    font-size: 80px;
    margin: -20px 50px 0px 50px;
    text-align: center;
    font-family: 'Barlow', sans-serif;
}
.main-logo {
    height: 450px;
    width: 450px;
    margin: 70px;
}

@media screen and (max-width: 320px) {
    .hero-container > h1 {
        font-size: 40px;
    }

    .main-logo {
        height: 250px;
        width: 250px;
        margin-top: 80px;
    }
}

@media screen and (min-width: 300px) {
    .hero-container > h1 {
        font-size: 55px;
    }

    .main-logo {
        height: 250px;
        width: 250px;
        margin-top: 80px;
    }
}

@media screen and (min-width: 768px) {
    .main-logo {
        height: 350px;
        width: 350px;
    }
}

/* iPhone 5 Portrait */
@media only screen and (min-device-width: 320px) and (max-device-height: 568px) and (orientation : landscape) and (-webkit-device-pixel-ratio: 2) {
    .hero-container {
        height: 50%;
    }
}
    
/* iPhone 5 Landscape */
@media only screen and (min-device-width: 320px) and (max-device-height: 568px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2) {
    .hero-container {
        height: 50%;
    }
}

/* iPhone 6, 7, 8 Portrait */
@media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2) {
    .hero-container {
        height: 50%;
    }
}

/* iPhone 6, 7, 8 Landscape */
@media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : landscape) and (-webkit-device-pixel-ratio: 2) {
    .hero-container {
        height: 50%;
    }
}

/* iPhone 6+, 7+, 8+ Portrait */
@media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2) {
    .hero-container {
        height: 50%;
    }
}

/* iPhone 6+, 7+, 8+ Landscape */
@media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation : landscape) and (-webkit-device-pixel-ratio: 2) {
    .hero-container {
        height: 50%;
    }
}

/* iPhone X Portrait */
@media only screen and (min-device-width: 375px) and (max-device-height: 812px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3) {
    .hero-container {
        height: 50%;
    }
}

/* iPhone X Landscape */
@media only screen and (min-device-width: 375px) and (max-device-height: 812px) and (orientation : landscape) and (-webkit-device-pixel-ratio: 3) {
    .hero-container {
        height: 50%;
    }
}

/* iPhone XS Max, XR Portrait */
@media only screen and (min-device-width: 414px) and (max-device-height: 896px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3) {
    .hero-container {
        height: 50%;
    }
}

/* Pixel 2 Portrait */
@media only screen and (min-device-width: 411px) and (max-device-height: 731px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3) {
    .hero-container {
        height: 50%;
    }
}

/* Samsung Galaxy S5 Portrait */
@media only screen and (min-device-width: 360px) and (max-device-height: 640px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3) {
    .hero-container {
        height: 50%;
    }
}

/* Samsung Galaxy S5 Landscape */
@media only screen and (min-device-width: 360px) and (max-device-height: 640px) and (orientation : landscape) and (-webkit-device-pixel-ratio: 3) {
    .hero-container {
        height: 50%;
    }
}

/* iPads (portrait and landscape) */
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    .hero-container {
        height: 50%;
    }
    .hero-container > h1 {
        font-size: 70px;
        margin-top: 0px;
    }
}

/* iPads (landscape) */
@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {
    .hero-container {
        height: 50%;
    }
}

/* iPad Pro Portrait */
@media only screen and (min-device-width : 1024px) and (max-device-width : 1366px) and (orientation : portrait) {
    .hero-container {
        height: 50%;
    }
}

/* Surface Duo Portrait */
@media only screen and (min-device-width : 540px) and (max-device-width : 720px) and (orientation : portrait) {
    .hero-container {
        height: 50%;
    }
}

/* Galaxy Fold Portrait */
@media only screen and (min-device-width : 280px) and (max-device-width : 653px) and (orientation : portrait) {
    .hero-container {
        height: 50%;
    }
}

@media screen and (min-width: 1024px) {
    .main-logo {
        width: 400px;
        height: 400px;
    }
    .hero-container > h1 {
        font-size: 80px;
    }
}

@media screen and (min-width: 2560px) {
    .main-logo {
        height: 600px;
        width: 600px;
        margin: 100px;
    }
    .hero-container > h1 {
        font-size: 130px;
    }
}
.genretag__container {
    margin: 0.25rem;
}

.genretag__btn {
    font-family: 'Poppins', sans-serif;
    font-size: 0.75rem;
    border-radius: 25px;
    padding: 0.25rem;
    width: 5.5rem;
    /* cursor: pointer; */
    transition: transform 0.3s ease;
    border: solid 1px #0466c8;
    background-color: #ffffff;
}

.genretag__btn:hover {
    transform: scale(0.95);
    background-color: #0466c8;
    border-color: #ffffff;
    color: #ffffff;
}
.cards {
    padding: 2rem 0rem 2rem 0rem;
    background-color: #01010B;
}
.cards__title {
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 40px;
    color: #ffffff;
    font-weight: bolder;
    margin: 5rem 0rem 5rem 0rem;
}
.cards__items {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}
.cards__items--img {
    top: 0;
    object-fit: cover;
    max-width: 100%;
    max-height: 100%;
    height: 45vh;
}
.cards__items--item {
    margin: 0 1rem;
}
.cards__items--link {
    display: flex;
    flex-flow :column;
    border: solid 1px #ffffff;
    overflow: hidden;
    text-decoration: none;
}

.cards__items--info {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-top: 1rem;
}

.cards__items--text {
    line-height: 1px;
    color: #ffffff;
    font-size: 14px;
    font-weight: 300;
    font-family: 'Poppins', sans-serif;
    margin: 0.5rem;
}

.dj-title {
    font-size: 20px;
    color: #0466c8;
    font-family: 'Poppins', sans-serif;
    margin: 0.25rem;
}

.buttons-container {
    display: flex;
}

@media screen and (min-width: 320px) {
    .cards__items--item {
        margin: 0rem 1rem 1.5rem;
    }
}

@media screen and (min-width: 1024px) {
    .cards__items--img {
        height: 38vh;
        object-fit: fill;
    }
}

@media screen and (min-width: 1440px) {
    .cards__items--img {
        height: 45vh;
    }
}
.schedule-container {
    /* height: 250vh; */
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    object-fit: contain;
    background-color: #020211;
}
.schedule-title {
    color: #007bff;
    font-weight: bolder;
    margin: 60px;
}

.schedule-table {
    width: 80%;
    margin-bottom: 75px;
}

tr {
    display: flex;
    justify-content: space-between;
    margin: 2rem;
    border-top: solid 2px #007bff;
}

td {
    color: #ffffff;
    margin: 1rem;
    font-size: 25px;
}

.show-name-text {
    margin-left: 40px;
}

.show-date-row {
    border-top: none;
    margin-bottom: -20px;
}

.show-date-text {
    font-size: 35px;
}

.takeover-show-text {
    color: #9C9DAD;
    margin-top: 0;
    margin-bottom: 10px;
}

@media screen and (max-width: 768px) {
    .show-date-text {
        text-align: center;
    }
}

@media screen and (min-width: 425px) {
    tr {
        margin: 1rem;
    }
    .schedule-table {
        width: 90%;
    }
}

@media screen and (min-width: 375px) {
    tr {
        margin: 1rem;
    }
    .schedule-table {
        width: 90%;
    }
}

@media screen and (min-width: 320px) {
    tr {
        margin: 1rem;
    }
    td {
        font-size: 11px;
        margin: 0.5rem;
    }
    .schedule-table {
        width: 90%;
    }
    .show-date-text {
        font-size: 22px;
        margin-bottom: 2rem;
    }
    .schedule-title {
        font-size: 2rem;
    }
}

@media screen and (min-width: 768px) {
    .show-date-text {
        font-size: 38px;
        text-align: left;
    }
    .schedule-table {
        width: 80%;
    }
    td {
        font-size: 24px;
        margin: 1rem;
    }
    .schedule-title {
        font-size: 3.25rem;
    }
}

@media screen and (min-width: 1024px) {
    tr {
        margin: 1.5rem;
    }
}

@media screen and (min-width: 2560px) {
    .schedule-title {
        font-size: 5.25rem;
    }
    .show-date-text {
        font-size: 60px;
    }
    td {
        font-size: 40px;
        margin: 2rem;
    }
}
.about-container {
    background-color: #01010B;
    color: white;
    width: 100%;
    object-fit: contain;
}

.about-title {
    padding: 7rem 7rem 0 7rem;
}

.about-text {
    padding: 3.5rem 7rem 7rem 7rem;
    margin: 0;
}

@media screen and (max-width: 320px) {
    .about-title {
        font-size: 2.5rem;
        padding: 3.5rem 3.5rem 0 3.5rem;
    }
    .about-text {
        padding: 3.5rem 3.5rem 7rem 3.5rem;
    }
}

@media screen and (min-width: 375px) {
    .about-title {
        font-size: 2.5rem;
        padding: 3.5rem 3.5rem 0 3.5rem;
    }
    .about-text {
        padding: 3.5rem 3.5rem 7rem 3.5rem;
    }
}

@media screen and (min-width: 425px) {
    .about-title {
        padding: 50px 50px 0 50px
    }
}

@media screen and (min-width: 768px) {
    .about-title {
        padding: 3.5rem 3.5rem 0 3.5rem;
    }
    .about-text {
        font-size: 20px;
    }
}

@media screen and (min-width: 1024px) {
    .about-title {
        font-size: 52px;
    }
}

@media screen and (min-width: 2560px) {
    .about-container {
        padding: 100px;
    }
    .about-title {
        font-size: 75px;
    }
    .about-text {
        font-size: 30px;
    }
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
    font-family: 'Poppins', sans-serif;
}
.footer-wrapper {
    width: 100%;
    height: 175px;
    background: #080A34;
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 1;
    margin-left: auto;
    margin-right: auto;
}
.footer-wrapper .left {
    display: flex;
    align-items: center;
}
.footer-wrapper .left .logo {
    margin-right: 25px;
    height: 120px;
    width: 240px;
}
.footer-wrapper .centre .nav-menu {
    display: flex;
    width: 100%;
    z-index: 9999;
}
.footer-wrapper .centre ul li {
    margin: 10px;
    font-size: 13px;
    color: #ffffff;
}
.footer-wrapper .centre ul li a {
    text-decoration: underline;
    color: #ffffff;
}
.nav-link {
    display: flex;
    align-items: center;
    height: 100%;
    color: #00132d;
    text-decoration: inherit;
    font-size: 14px;
    background:
    linear-gradient(
    to bottom, var(--mainColor) 0%, var(--mainColor) 100%);
    background-position: 0 100%;
    background-repeat: repeat-x;
    background-size: 3px 3px;
    color: #000;
    text-decoration: none;
    transition: background-size .2s;
}
.footer-wrapper .right ul {
    display: flex;
    flex-direction: column;
}
.footer-wrapper .right ul li {
    margin: 0 0 7px 7px;
    color: #ffffff;
    font-size: 13px;
}

@media screen and (max-width: 768px) {
    .footer-wrapper .left .logo {
        margin-left: 0.5rem;
    }
    .wrapper .top-nav .right ul {
        display: none;
    }
    .wrapper .top-nav .centre .nav-menu {
        display: none;
    }
    .nav-menu-active {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-top: 1.9rem;
        background-color: #ffffff;
        left: 0;
        opacity: 1;
        transition: all 0.6 ease;
        z-index: 1;
        position: absolute;
    }
    .logo {
        height: 55px;
        width: 55px;
        margin: -14px -10px -10px -25px;
    }
    .nav-link {
        text-align: center;
        padding: 1.5rem;
        width: 100%;
        display: table;
        background: none;
        cursor: auto;
        font-size: 1.3rem;
    }
    .nav-item {
        display: flex;
        justify-content: center;
        width: 100%;
        margin: 10px;
    }
    .wrapper .top-nav .right .menu-icon {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;
        color: #00132d;
    }
    .menu-icon {
        margin-top: -10px;
    }
    .fa .fa-times {
        font-size: 2rem;
    }
    .hero-container {
        position: relative;
    }
    .cards {
        position: relative;
    }
}

@media screen and (max-width: 768px) {
    .footer-wrapper .centre ul li {
        font-size: 11px;
    }
    .footer-wrapper .right ul li {
        font-size: 11px;
    }
}

@media screen and (max-width: 320px) {
    .nav-item {
        justify-content: flex-end;
        font-size: 7px;
        flex-wrap: wrap;
    }
    .footer-wrapper .left .logo {
        height: 70px;
        width: 70px;
    }
    .footer-right {
        font-size: 9px;
        width: 40%;
        flex-wrap: wrap;
        color: white;
    }
    .footer-wrapper .right ul {
        display: none;
    }
    .footer-wrapper .centre ul li {
        text-align: right;
    } 
    .footer-wrapper .centre ul li a {
        font-size: 8px;
        text-align: center;
    }
}

@media screen and (min-width: 375px) {
    .nav-item {
        justify-content: flex-end;
        font-size: 8px;
        flex-wrap: wrap;
    }
    .footer-right {
        font-size: 9px;
        width: 90%;
        flex-wrap: wrap;
        color: white;
    }
    .footer-wrapper .right ul {
        display: none;
    }
    .footer-wrapper .centre ul li {
        text-align: right;
        font-size: 9px;
    }
    .footer-wrapper .centre ul li a {
        font-size: 7px;
    }
    .centre {
        width: 100%;
    }
    .footer-wrapper .centre .nav-menu {
        width: 90%;
    }
}

@media screen and (min-width: 375px) {
    .footer-wrapper .left .logo {
        height: 80px;
        width: 80px;
    }
}

@media screen and (min-width: 425px) {
    .nav-item {
        justify-content: flex-end;
        font-size: 8px;
        flex-wrap: wrap;
    }
    .footer-right {
        font-size: 10px;
        width: 60%;
        color: white;
    }
    .footer-wrapper .centre ul li {
        width: 100%;
    }

    .footer-wrapper .centre .nav-menu {
        width: 90%;
    }
    .centre {
        width: 90%;
    }
}

@media screen and (min-width: 425px) {
    .footer-wrapper .left .logo {
        height: 80px;
        width: 80px;
    }
}

@media screen and (min-width: 768px) {
    .footer-wrapper .left .logo {
        height: 100px;
        width: 100px;
    }
    .footer-right {
        display: flex;
        justify-content: flex-end;
        font-size: 10px;
        width: 60%;
        color: white;
    }
    .footer-wrapper .right ul {
        display: flex;
        justify-content: flex-end;
    }
    .footer-wrapper {
        width: 100%;
    }
    .bottom-nav {
        padding: 0 5px;
    }
    .centre {
        justify-content: flex-start;
    }
}

@media screen and (min-width: 1024px) {
    .footer-wrapper .left .logo {
        height: 100px;
        width: 100px;
    }
    .right {
        width: 10%;
    }
    .footer-right {
        width: 20%;
        color: white;
    }
    .footer-wrapper .right ul {
        display: flex;
    }
    .centre {
        width: 33%;
    }
    .footer-wrapper .centre ul li a {
        font-size: 10px;
    }
    .nav-item {
        font-size: 9px;
    }
}

@media screen and (min-width: 1440px) {
    .footer-wrapper .left .logo {
        width: 120px;
        height: 120px;
    }
    .footer-wrapper .centre .nav-menu {
        width: 80%;
    }
    .footer-right {
        width: 15%;
    }
}
.genres__container {
    height: 100vh;
    width: 100%;
    padding-top: 140px;
    font-family: 'Poppins', sans-serif;
}
.title__row {
    width: 100%;
    background-color: #e7e9f0;
    padding: 20px;
    text-align: center;
    color: #0059E8;
}
.grid__col {
    height: 100vh;
    border: solid 1px #00132d;
    background-color: #e7e9f0;
    color: #0059E8;
    width: 300px;
    text-align: center;
    margin-top: 5px;
}
.genres__items {
    display: flex;
    justify-content: space-around;
    list-style-type: none;
    flex-wrap: wrap;
}
