.player-wrapper {
    display: flex;
}

.play-btn {
    background: none;
    border: none;
    display: flex;
    align-items: center;
    color: white;
    /* Uncomment the line below */
    cursor: pointer;
}
