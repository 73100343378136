.cards {
    padding: 2rem 0rem 2rem 0rem;
    background-color: #01010B;
}
.cards__title {
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 40px;
    color: #ffffff;
    font-weight: bolder;
    margin: 5rem 0rem 5rem 0rem;
}
.cards__items {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}
.cards__items--img {
    top: 0;
    object-fit: cover;
    max-width: 100%;
    max-height: 100%;
    height: 45vh;
}
.cards__items--item {
    margin: 0 1rem;
}
.cards__items--link {
    display: flex;
    flex-flow :column;
    border: solid 1px #ffffff;
    overflow: hidden;
    text-decoration: none;
}

.cards__items--info {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-top: 1rem;
}

.cards__items--text {
    line-height: 1px;
    color: #ffffff;
    font-size: 14px;
    font-weight: 300;
    font-family: 'Poppins', sans-serif;
    margin: 0.5rem;
}

.dj-title {
    font-size: 20px;
    color: #0466c8;
    font-family: 'Poppins', sans-serif;
    margin: 0.25rem;
}

.buttons-container {
    display: flex;
}

@media screen and (min-width: 320px) {
    .cards__items--item {
        margin: 0rem 1rem 1.5rem;
    }
}

@media screen and (min-width: 1024px) {
    .cards__items--img {
        height: 38vh;
        object-fit: fill;
    }
}

@media screen and (min-width: 1440px) {
    .cards__items--img {
        height: 45vh;
    }
}